import { useLazyQuery, useQueryRefHandlers } from '@apollo/client'
import {
  LoadingButton,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { BalanceChange } from '../../BalanceChange'
import { FragmentType, graphql, getFragmentData } from '../../gql'
import RouterLink from '../../RouterLink'
import { TimelineLoader } from './timelineLoader'
import { useLoaderData } from 'react-router-typesafe'
import { useEffect } from 'react'
import { useCoin } from '../../coin/CoinContainer'

const UnconfirmedAddressTransactionsFrament = graphql(`
  fragment UnconfirmedAddressTransactionsFrament on PaginatedIUnconfirmedAddressTransactionResponse {
    items {
      txid
      balanceChange
      timestamp
    }
    hasMore
  }
`)

const LoadMoreQuery = graphql(`
  query LoadMoreUnconfirmedAddressTransactionsQuery(
    $coin: String!
    $address: String!
    $cursor: UnconfirmedAddressTransactionCursor
  ) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      address(address: $address) {
        address
        unconfirmedTransactions(direction: DESC, cursor: $cursor, limit: 10) {
          ...UnconfirmedAddressTransactionsFrament
        }
      }
    }
  }
`)

const addressTransactionSubscription = graphql(`
  subscription AddressTransactionSubscription($coin: String!, $address: String!) {
    addressTransactionReceived(symbol: $coin, address: $address) {
      txid
      balanceChange
      timestamp
    }
  }
`)

export function UnconfirmedAddresTransactionsTimeline(props: {
  unconfirmedTxCount: number
  unconfirmedBalanceChange: number
  confirmedBalance: number
  query: FragmentType<typeof UnconfirmedAddressTransactionsFrament>
}) {
  const { address } = useParams()
  if (!address) throw new Error('Param address is required')
  const coin = useCoin()
  const res = getFragmentData(UnconfirmedAddressTransactionsFrament, props.query)
  const [query, { loading }] = useLazyQuery(LoadMoreQuery, {
    fetchPolicy: 'network-only'
  })
  const queryRef = useLoaderData<TimelineLoader>()
  const { subscribeToMore } = useQueryRefHandlers(queryRef)
  useEffect(() => {
    return subscribeToMore({
      document: addressTransactionSubscription,
      variables: {
        coin,
        address
      },
      updateQuery: (prev, { subscriptionData }) => {
        const addressObj = prev.coinBySymbol?.address
        if (!addressObj) throw new Error('prev is undefined')
        const addressTransaction = subscriptionData.data.addressTransactionReceived
        return {
          ...prev,
          coinBySymbol: {
            ...prev.coinBySymbol,
            address: {
              ...addressObj,
              unconfirmedBalanceChange:
                addressObj.unconfirmedBalanceChange + addressTransaction.balanceChange,
              unconfirmedTxCount: addressObj.unconfirmedTxCount + 1,
              unconfirmedTransactions: {
                ...addressObj.unconfirmedTransactions,
                items: [addressTransaction, ...addressObj.unconfirmedTransactions.items]
              }
            }
          }
        } as typeof prev
      }
    })
  }, [subscribeToMore, coin, address])
  if (res.items.length === 0) return null
  return (
    <TimelineItem position="left">
      <TimelineOppositeContent>
        {props.unconfirmedTxCount > 1 && (
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
            {props.unconfirmedTxCount} transactions (
            <BalanceChange
              component={'span'}
              balanceChange={props.unconfirmedBalanceChange}
              display="inline"
              variant="inherit"
            />{' '}
            {coin.toUpperCase()})
          </Typography>
        )}
        <Stack gap={0.5} alignItems="flex-start">
          {res.items.map((item) => (
            <Typography sx={{ whiteSpace: { xs: 'normal', sm: 'nowrap' } }} key={item.txid}>
              <BalanceChange balanceChange={item.balanceChange} component="span" />
              {' ' + coin.toUpperCase() + ' '}
              <RouterLink
                sx={{
                  display: 'inline',
                  whiteSpace: 'normal',
                  overflowWrap: 'anywhere',
                  flex: '0 1 290px'
                }}
                to={{
                  pathname: `/${coin}/transaction/${item.txid}`,
                  search: `address=${address}`
                }}
              >
                {item.txid}
              </RouterLink>
            </Typography>
          ))}
        </Stack>
        {res.hasMore && (
          <LoadingButton
            loading={loading}
            variant="outlined"
            size="small"
            onClick={() => {
              query({
                variables: {
                  coin,
                  address,
                  cursor: {
                    txid: res.items[res.items.length - 1].txid,
                    timestamp: res.items[res.items.length - 1].timestamp
                  }
                }
              })
            }}
          >
            Load more
          </LoadingButton>
        )}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          variant="outlined"
          color={props.unconfirmedBalanceChange < 0 ? 'error' : 'success'}
        />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
          Unconfirmed
        </Typography>

        <Typography>
          Balance{' '}
          <Typography component={'span'}>
            {props.confirmedBalance + props.unconfirmedBalanceChange} {coin.toUpperCase()}
          </Typography>
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}
