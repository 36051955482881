import { Snackbar, styled } from '@mui/material'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { CellElement } from '../common/table/CellElement'
import { BodyRowElement, HeaderRowElement } from '../common/table/RowElement'
import { RowGroupElement } from '../common/table/RowGroupElement'
import { TableElement } from '../common/table/TableElement'
import { FragmentType, getFragmentData, graphql } from '../gql'
import RouterLink, { RouterLinkProps } from '../RouterLink'

export const blocksTableItemFragment = graphql(`
  fragment BlocksTableItemFragment on IBlockHash {
    height
    hash
    block {
      ...BlocksTableRowFragment
    }
  }
`)

export const blocksTableFragment = graphql(`
  fragment BlocksTableFragment on PaginatedIBlockHashResponse {
    items {
      ...BlocksTableItemFragment
    }
  }
`)

export const blocksTableRowFragment = graphql(`
  fragment BlocksTableRowFragment on IBlock {
    height
    hash
    txCount
    size
    time
  }
`)

const HeightCell = styled(CellElement)(() => ({
  flex: '1 0 70px'
}))

const TimeCell = styled(CellElement)(() => ({
  flex: '1 2 155px'
}))

const HashCell = styled(CellElement)(() => ({
  flex: '1 3 550px'
}))

const HashLinkCell = styled(HashCell)<{ component: typeof RouterLink } & RouterLinkProps>(() => ({
  overflowWrap: 'anywhere'
}))

const TxCountCell = styled(CellElement)(() => ({
  flex: '1 0 75px'
}))

export function BlocksTableRow({
  query,
  bip44_symbol
}: {
  query: FragmentType<typeof blocksTableRowFragment>
  bip44_symbol: string
}) {
  const block = getFragmentData(blocksTableRowFragment, query)
  return (
    <BodyRowElement>
      <HeightCell>{block.height}</HeightCell>
      <TimeCell>{dayjs(block.time).format('L LT')}</TimeCell>
      <HashLinkCell
        component={RouterLink}
        to={`/${bip44_symbol.toLowerCase()}/blocks/${block.hash}`}
      >
        {block.hash}
      </HashLinkCell>
      <TxCountCell>{block.txCount}</TxCountCell>
    </BodyRowElement>
  )
}

export function BlocksTable({
  query,
  bip44_symbol,
  limit
}: {
  query: FragmentType<typeof blocksTableFragment>
  bip44_symbol: string
  limit?: number
}) {
  const { items } = getFragmentData(blocksTableFragment, query)
  const firstItem = getFragmentData(blocksTableItemFragment, items[0])
  const height = firstItem.height
  const [lastNotificationHeight, setLastNotificationHeight] = useState(height)
  const handleSnackbarClose = useCallback(() => {
    setLastNotificationHeight(height)
  }, [height])
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={height > lastNotificationHeight}
        onClose={handleSnackbarClose}
        message={`New block ${height}`}
        autoHideDuration={5000}
      />
      <TableElement aria-label="Blocks">
        <RowGroupElement>
          <HeaderRowElement>
            <HeightCell>Height</HeightCell>
            <TimeCell>Time</TimeCell>
            <HashCell>Hash</HashCell>
            <TxCountCell>Tx count</TxCountCell>
          </HeaderRowElement>
        </RowGroupElement>
        <RowGroupElement>
          {items.slice(0, limit).map((item) => {
            const { height, block } = getFragmentData(blocksTableItemFragment, item)
            return <BlocksTableRow key={height} query={block} bip44_symbol={bip44_symbol} />
          })}
        </RowGroupElement>
      </TableElement>
    </>
  )
}
