import { Launch } from '@mui/icons-material'
import { Card, CardContent, CardHeader, IconButton } from '@mui/material'
import RouterLink from '../../RouterLink'

export function UnconfirmedTransactionsCardLayout(props: { content: React.ReactNode }) {
  return (
    <Card sx={{ height: 1 }}>
      <CardHeader
        title="Latest transactions"
        action={
          <IconButton component={RouterLink} to={`mempool`}>
            <Launch />
          </IconButton>
        }
      />
      <CardContent>{props.content}</CardContent>
    </Card>
  )
}
