import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { FragmentType, getFragmentData, graphql } from '../gql'
import RouterLink from '../RouterLink'

export const blockCardHeaderActionFragment = graphql(`
  fragment BlockCardHeaderActionFragment on IBlock {
    previousBlockHash
    nextBlock {
      hash
    }
  }
`)

export function BlockCardHeaderAction({
  block
}: {
  block: FragmentType<typeof blockCardHeaderActionFragment>
}) {
  const { previousBlockHash, nextBlock } = getFragmentData(blockCardHeaderActionFragment, block)
  return (
    <>
      <IconButton
        disabled={!previousBlockHash}
        component={RouterLink}
        to={`../${previousBlockHash || ''}`}
        aria-label="Previous block"
      >
        <ChevronLeft />
      </IconButton>
      <IconButton
        disabled={!nextBlock}
        component={RouterLink}
        to={`../${nextBlock?.hash || ''}`}
        aria-label="Next block"
      >
        <ChevronRight />
      </IconButton>
    </>
  )
}
