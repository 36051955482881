import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../../ApolloClient'
import { graphql } from '../../gql'

const addressQuery = graphql(`
  query addressBlocks2($coin: String!, $address: String!, $cursor: AddressBalanceCursor) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      name
      address(address: $address) {
        address
        unconfirmedTransactions(direction: DESC, limit: 10) {
          ...UnconfirmedAddressTransactionsFrament
        }
        unconfirmedBalanceChange
        unconfirmedTxCount
        addressBlocks(direction: DESC, cursor: $cursor) {
          items {
            address {
              address
            }
            height
            blockHash {
              height
              block {
                time
                hash
              }
            }
            balance
            ...AddressBlockFragment2
          }
          hasMore
        }
      }
    }
  }
`)

export const createTimelineLoader = (defaultCoin?: string) =>
  (({ params }) => {
    const { address, coin = defaultCoin } = params
    if (!address) throw new Error('Param address is required')
    if (!coin) throw new Error('Param coin is required')
    return preloadQuery(addressQuery, {
      variables: { coin, address }
    })
  }) satisfies LoaderFunction

export type TimelineLoader = ReturnType<typeof createTimelineLoader>
