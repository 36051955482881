import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'

const blocksQuery = graphql(`
  query blocks($coin: String!, $cursor: BlockHashCursor) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      blocks(direction: DESC, cursor: $cursor) {
        ...BlocksTableFragment
        ...BlocksLoadMoreFragment
      }
    }
  }
`)

export const createBlocksLoader = (defaultCoin?: string) =>
  (({ params: { coin = defaultCoin } }) => {
    if (!coin) throw new Error('Param coin is required')
    return preloadQuery(blocksQuery, { variables: { coin } })
  }) satisfies LoaderFunction

export type BlocksLoader = ReturnType<typeof createBlocksLoader>
