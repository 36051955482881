import { Suspense } from 'react'
import { useReadQuery } from '@apollo/client'
import { Box, Grid } from '@mui/material'
import { useLoaderData } from 'react-router-typesafe'
import { coinsLoader } from './coinsLoader'
import { CoinItem, SkeletonItem } from './CoinItem'

function CoinGridItems() {
  const queryRef = useLoaderData<typeof coinsLoader>()
  const { data } = useReadQuery(queryRef)
  return (
    <>
      {data?.coins.map((coin) => (
        <Grid item key={coin.name} xs={4}>
          <CoinItem coin={coin} />
        </Grid>
      ))}
    </>
  )
}

function CoinsPage() {
  return (
    <Box>
      <Grid container spacing={2} justifyContent="center" columns={{ xs: 4, sm: 8, md: 12 }}>
        <Suspense
          fallback={
            <>
              {Array.from({ length: 3 }, (_, i) => (
                <Grid item key={i} xs={4}>
                  <SkeletonItem />
                </Grid>
              ))}
            </>
          }
        >
          <CoinGridItems />
        </Suspense>
      </Grid>
    </Box>
  )
}

export default CoinsPage
