import { Suspense, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Alert, Box, Card, CardContent, CardHeader, Grid, LinearProgress } from '@mui/material'
import { TransactionOutputs } from './TransactionOutputs'
import { TransactionInputs } from './TransactionInputs'
import {
  TransactionCardHeader,
  TransactionDetails,
  TransactionDetailsSkeleton
} from './TransactionDetails'
import { useLoaderData } from 'react-router-typesafe'
import { TransactionLoader } from './transactionLoader'
import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { CoinSymbolToName } from '../util/CoinUtil'
import { blockSubscription } from '../coin/blocks/BlocksCard'
import { useCoin } from '../coin/CoinContainer'

interface Props {
  coin: string
  txid: string
  address?: string
  highlightedInput?: number
  highlightedOutput?: number
}

function TransactionPageLoaded({
  coin,
  txid,
  highlightedInput,
  highlightedOutput,
  address
}: Props) {
  const queryRef = useLoaderData<TransactionLoader>()
  const { data } = useReadQuery(queryRef)
  const { subscribeToMore, refetch } = useQueryRefHandlers(queryRef)
  const bip44_symbol = data.coinBySymbol?.bip44_symbol

  if (!data.coinBySymbol) throw new Error('Coin not found')
  const { transaction } = data.coinBySymbol

  useEffect(() => {
    if (!bip44_symbol) return
    return subscribeToMore({
      document: blockSubscription,
      variables: { coin: bip44_symbol },
      updateQuery: (prev, { subscriptionData }) => {
        const coinBySymbol = prev.coinBySymbol
        if (!coinBySymbol) throw new Error('prev is undefined')
        const block = subscriptionData.data.blockReceived
        const { hash, height } = block
        const oldBlocks = prev.coinBySymbol?.blocks.items || []
        const items = [
          {
            hash,
            height,
            block,
            __typename: `${CoinSymbolToName[coinBySymbol.bip44_symbol]}BlockHash`
          },
          ...oldBlocks
        ]
        if (coinBySymbol.transaction) {
          if (!coinBySymbol.transaction.blockHash) refetch() //Unconfirmed transaction. Refetch to check if it's confirmed
        }
        return {
          ...prev,
          coinBySymbol: {
            ...coinBySymbol,
            blocks: {
              ...coinBySymbol.blocks,
              items
            }
          }
        } as typeof prev
      }
    })
  }, [subscribeToMore, bip44_symbol, refetch, txid])

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <TransactionCardHeader txid={txid} transaction={transaction} />
            <CardContent>
              {transaction ? (
                <TransactionDetails transaction={transaction} />
              ) : (
                <Alert severity="error">Transaction not found</Alert>
              )}
            </CardContent>
          </Card>
        </Grid>
        {transaction && (
          <Grid item xs={12} sm={6}>
            <TransactionInputs
              coin={coin}
              highlightedInput={highlightedInput}
              address={address}
              inputs={transaction}
            />
          </Grid>
        )}
        {transaction && (
          <Grid item xs={12} sm={6}>
            <TransactionOutputs
              highlightedOutput={highlightedOutput}
              address={address}
              outputs={transaction}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

function TransactionPageSkeleton({ txid }: { txid: string }) {
  return (
    <Box>
      <Grid container spacing={1} columns={12}>
        <Grid item xs={12}>
          <Card>
            <TransactionCardHeader txid={txid} />
            <CardContent>
              <TransactionDetailsSkeleton />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title={`Inputs`} titleTypographyProps={{ variant: 'h6' }}></CardHeader>
            <CardContent>
              <LinearProgress
                sx={{ width: '30%', maxWidth: 120, mx: 'auto', my: 5 }}
                color="inherit"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title={`Outputs`} titleTypographyProps={{ variant: 'h6' }}></CardHeader>
            <CardContent>
              <LinearProgress
                sx={{ width: '30%', maxWidth: 120, mx: 'auto', my: 5 }}
                color="inherit"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

function TransactionPage() {
  const { txid } = useParams()
  const coin = useCoin()
  const [search] = useSearchParams()
  const address = search.get('address') || undefined
  const searchInput = search.get('input')
  const searchOutput = search.get('output')
  const highlightedInput = searchInput ? Number.parseInt(searchInput) : undefined
  const highlightedOutput = searchOutput ? Number.parseInt(searchOutput) : undefined

  if (!txid) throw new Error('Param txid is required')
  useEffect(() => {
    const oldTitle = document.title
    document.title = `${CoinSymbolToName[coin.toUpperCase()]} Transaction ${txid}`
    return () => {
      document.title = oldTitle
    }
  }, [coin, txid])
  return (
    <Suspense fallback={<TransactionPageSkeleton txid={txid} />}>
      <TransactionPageLoaded
        coin={coin}
        txid={txid}
        address={address}
        highlightedInput={highlightedInput}
        highlightedOutput={highlightedOutput}
      />
    </Suspense>
  )
}

export default TransactionPage
