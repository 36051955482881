import { useLazyQuery, useQueryRefHandlers } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { FragmentType, graphql, getFragmentData } from '../gql'
import { outputFragment, scriptPubkeyFragment, TransactionOutput } from './TransactionOutput'
import { useEffect } from 'react'
import { TransactionLoader } from './transactionLoader'
import { useLoaderData } from 'react-router-typesafe'
import { useCoin } from '../coin/CoinContainer'

const LoadMoreQuery = graphql(`
  query LoadMoreTransactionOutputs(
    $coin: String!
    $txid: String!
    $cursor: TransactionOutputCursor
  ) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      transaction(txid: $txid) {
        txid
        outputs(direction: ASC, limit: 100, cursor: $cursor) {
          items {
            n
            ...OutputFragment
          }
          hasMore
        }
      }
    }
  }
`)

const TransactionOutputsFragment = graphql(`
  fragment TransactionOutputsFragment on ITransaction {
    outputs(direction: ASC, limit: 100, cursor: $outputCursor) {
      items {
        n
        ...OutputFragment
      }
      hasMore
    }
    outputCount
  }
`)

const transactionOutputSpentSubscription = graphql(`
  subscription TransactionOutputSpentSubscription($coin: String!, $txid: String!) {
    transactionOutputSpent(symbol: $coin, txid: $txid) {
      n
      spendingIndex
      spendingTxid
    }
  }
`)

export function TransactionOutputs(props: {
  highlightedOutput?: number
  address?: string
  outputs: FragmentType<typeof TransactionOutputsFragment>
}) {
  const { highlightedOutput, address } = props
  const { txid } = useParams()
  if (!txid) throw new Error('Param txid is required')
  const coin = useCoin()
  const data = getFragmentData(TransactionOutputsFragment, props.outputs)
  const [query, { loading }] = useLazyQuery(LoadMoreQuery, {
    fetchPolicy: 'network-only'
  })

  const queryRef = useLoaderData<TransactionLoader>()
  const { subscribeToMore } = useQueryRefHandlers(queryRef)
  const hasUnspent = data.outputs.items
    .map((output) => getFragmentData(outputFragment, output))
    .some((output) => {
      if (output.spendingTxid) return false //output is spent
      const scriptPubKey = getFragmentData(scriptPubkeyFragment, output)
      if (scriptPubKey.scriptPubKey.asm.includes('OP_RETURN')) return false //output is unspendable
      return true
    })

  useEffect(() => {
    if (!hasUnspent) return
    if (!coin) return
    return subscribeToMore({
      document: transactionOutputSpentSubscription,
      variables: { coin, txid },
      updateQuery: (prev, { subscriptionData }) => {
        const transaction = prev.coinBySymbol?.transaction
        if (!transaction) throw new Error('prev is undefined')
        return {
          ...prev,
          coinBySymbol: {
            ...prev.coinBySymbol,
            transaction: {
              ...transaction,
              outputs: {
                ...transaction.outputs,
                items: transaction.outputs.items.map((output) =>
                  output.n === subscriptionData.data.transactionOutputSpent.n
                    ? { ...output, ...subscriptionData.data.transactionOutputSpent }
                    : output
                )
              }
            }
          }
        } as typeof prev
      }
    })
  }, [subscribeToMore, coin, txid, hasUnspent])
  return (
    <Card sx={{ flex: '1 1 100%' }}>
      <CardHeader
        title={`Outputs (${data.outputCount})`}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>Value</TableCell>
                <TableCell sx={{ border: 'none' }} aria-label="Spending input"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.outputs.items.map((output) => (
                <TransactionOutput
                  key={output.n}
                  query={output}
                  highlightedAddress={address ?? undefined}
                  highlightedOutput={highlightedOutput}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {data.outputs.hasMore && (
          <LoadingButton
            loading={loading}
            variant="outlined"
            size="small"
            onClick={() => {
              query({
                variables: {
                  coin,
                  txid,
                  cursor: {
                    n: data.outputs.items[data.outputs.items.length - 1].n
                  }
                }
              })
            }}
          >
            Load more
          </LoadingButton>
        )}
      </CardContent>
    </Card>
  )
}
