import { createContext, useContext, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'

const CoinContext = createContext('lol')

export const useCoin = () => {
  return useContext(CoinContext)
}

export function CoinContainer() {
  const { coin } = useParams()
  if (!coin) throw new Error('Param coin is required')
  useEffect(() => {
    const favicon = document.getElementById('favicon')
    if (!favicon) return
    if ('href' in favicon && typeof favicon.href === 'string') {
      const oldHref = favicon.href
      favicon.href = `${process.env.PUBLIC_URL}/favicon_${coin.toLowerCase()}.ico`
      return () => {
        favicon.href = oldHref
      }
    }
  }, [coin])
  return (
    <CoinContext.Provider value={coin}>
      <Outlet />
    </CoinContext.Provider>
  )
}

export function DashContainer() {
  useEffect(() => {
    const favicon = document.getElementById('favicon')
    if (!favicon) return
    if ('href' in favicon && typeof favicon.href === 'string') {
      const oldHref = favicon.href
      favicon.href = `${process.env.PUBLIC_URL}/favicon_dash.ico`
      return () => {
        favicon.href = oldHref
      }
    }
  }, [])
  return (
    <CoinContext.Provider value="dash">
      <Outlet />
    </CoinContext.Provider>
  )
}
