import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { useLoaderData } from 'react-router-typesafe'
import { UnconfirmedTransactionsTable } from '../../mempool/UnconfirmedTransactionsTable'
import { CoinPageLoader } from '../CoinPage'
import { UnconfirmedTransactionsCardLayout } from './UnconfirmedTransactionsCardLayout'
import { useEffect } from 'react'
import { graphql } from '../../gql'

const transactionSubscription = graphql(`
  subscription transactionReceived($coin: String!) {
    transactionReceived(symbol: $coin) {
      ...UnconfirmedTransactionFragment
    }
  }
`)

export function UnconfirmedTransactionsCard() {
  const queryRef = useLoaderData<CoinPageLoader>()
  const { data } = useReadQuery(queryRef)
  const { subscribeToMore } = useQueryRefHandlers(queryRef)
  const coin = data.coinBySymbol
  const bip44_symbol = coin?.bip44_symbol
  useEffect(() => {
    if (!bip44_symbol) return
    return subscribeToMore({
      document: transactionSubscription,
      variables: { coin: bip44_symbol },
      updateQuery: (prev, { subscriptionData }) => {
        const coinBySymbol = prev.coinBySymbol
        if (!coinBySymbol) throw new Error('prev is undefined')
        return {
          ...prev,
          coinBySymbol: {
            ...coinBySymbol,
            mempool: {
              ...coinBySymbol.mempool,
              transactions: {
                ...prev.coinBySymbol?.mempool.transactions,
                items: [
                  subscriptionData.data.transactionReceived,
                  ...coinBySymbol.mempool.transactions.items
                ].slice(0, 5)
              }
            }
          }
        } as typeof prev
      }
    })
  }, [subscribeToMore, bip44_symbol])
  if (!coin) throw new Error('Coin not found')
  return (
    <UnconfirmedTransactionsCardLayout
      content={
        <UnconfirmedTransactionsTable
          query={coin.mempool.transactions}
          bip44_symbol={coin.bip44_symbol}
          limit={5}
        />
      }
    />
  )
}
