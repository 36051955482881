import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'

const blockTransactionsByHeightQuery = graphql(`
  query blockTransactionsByHeight(
    $coin: String!
    $height: Int!
    $cursor: ConfirmedTransactionCursor
  ) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      ...LastBlockHeightFragment
      blockByHeight(height: $height) {
        height
        block {
          hash
          height
          ...BlockTransactionsFragment
          ...BlockCardContentFragment
          ...BlockCardHeaderActionFragment
        }
      }
    }
  }
`)

const blockTransactionsByHashQuery = graphql(`
  query blockTransactionsByHash(
    $coin: String!
    $hash: String!
    $cursor: ConfirmedTransactionCursor
  ) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      ...LastBlockHeightFragment
      block(hash: $hash) {
        hash
        height
        ...BlockTransactionsFragment
        ...BlockCardContentFragment
        ...BlockCardHeaderActionFragment
      }
    }
  }
`)

export const createBlockLoader = (defaultCoin?: string) =>
  (({ params }) => {
    const { blockId, coin = defaultCoin } = params
    if (!blockId) throw new Error('Param blockId is required')
    if (!coin) throw new Error('Param coin is required')
    const height = Number(blockId)
    return Number.isInteger(height)
      ? preloadQuery(blockTransactionsByHeightQuery, {
          variables: { coin, height }
        })
      : preloadQuery(blockTransactionsByHashQuery, {
          variables: { coin, hash: blockId }
        })
  }) satisfies LoaderFunction

export type BlockLoader = ReturnType<typeof createBlockLoader>
