import {
  ApolloClient,
  createQueryPreloader,
  HttpLink,
  InMemoryCache,
  isReference,
  Reference,
  split,
  StoreObject
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { KeyFieldsFunction } from '@apollo/client/cache/inmemory/policies'
import { StrictTypedTypePolicies, ICoinFieldPolicy } from './gql/apollo-helpers'
import PossibleTypesData from './gql/possible-types'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { Kind, OperationTypeNode } from 'graphql'

type CoinName = NonNullable<
  {
    [P in keyof StrictTypedTypePolicies]: Required<
      NonNullable<NonNullable<StrictTypedTypePolicies[P]>['fields']>
    > extends Required<ICoinFieldPolicy>
      ? P
      : never
  }[keyof StrictTypedTypePolicies]
>

export type RequiredCoinNames = Exclude<CoinName, 'ICoin'>

type CoinsRequired = Required<Pick<StrictTypedTypePolicies, RequiredCoinNames>>

const createPolicy = <E extends RequiredCoinNames>(prefix: E): CoinsRequired[E] => {
  return {
    fields: {
      block: (_, { args, toReference }) => {
        if (!args || !('hash' in args)) throw new Error('Hash is required')
        return toReference({
          __typename: `${prefix}Block`,
          hash: args.hash
        })
      },
      blockByHeight: (_, { args, toReference }) => {
        if (!args || !('height' in args)) throw new Error('Height is required')
        return toReference({
          __typename: `${prefix}BlockHash`,
          height: args.height
        })
      },
      address: (_, { args, toReference }) => {
        if (!args || !('address' in args)) throw new Error('Address is required')
        return toReference({
          __typename: `${prefix}Address`,
          address: args.address
        })
      },
      confirmedTransaction: (_, { args, toReference }) => {
        if (!args || !('height' in args) || !('tx_n' in args))
          throw new Error('Height and tx_n are required')
        return toReference({
          __typename: `${prefix}ConfirmedTransaction`,
          height: args.height,
          txN: args.tx_n
        })
      },
      transaction: (_, { args, toReference }) => {
        if (!args || !('txid' in args)) throw new Error('Txid is required')
        return toReference({
          __typename: `${prefix}Transaction`,
          txid: args.txid
        })
      }
    }
  }
}

const createCoinPolicies = (): CoinsRequired => {
  return {
    Bitcoin: createPolicy('Bitcoin'),
    Dash: createPolicy('Dash'),
    Litecoin: createPolicy('Litecoin'),
    Dogecoin: createPolicy('Dogecoin')
    /*BitcoinCash: createPolicy('BitcoinCash')*/
  }
}

const typePolicies: StrictTypedTypePolicies = {
  ...createCoinPolicies(),
  Query: {
    fields: {
      coinBySymbol: (_, { args, toReference, cache }) => {
        if (!args || !('symbol' in args)) throw new Error('Symbol is required')
        return toReference({
          __typename: 'ICoin',
          bip44_symbol: args.symbol.toUpperCase()
        })
      }
    }
  },
  ICoin: {
    keyFields: ((e, context) => {
      const bip44_symbol = context.readField('bip44_symbol', e)
      if (typeof bip44_symbol !== 'string') throw new Error('bip44_symbol is not a string!')
      const obj = { bip44_symbol: bip44_symbol.toUpperCase() }
      return `ICoin:${JSON.stringify(obj)}`
    }) as KeyFieldsFunction as never, //StrictTypedTypePolicies is missing KeyFieldsFunction from keyFields typings
    merge: true,
    fields: {
      blocks: {
        keyArgs: false,
        //keyArgs: ['cursor'],
        read(existing, { args, readField }) {
          if (!args?.direction) throw new Error('Direction is required')
          if (existing) {
            const items = Object.values<StoreObject | Reference>(existing.items)
            items.sort((a, b) => {
              const aHeight = readField('height', a)
              const bHeight = readField('height', b)
              if (typeof aHeight !== 'number') throw new Error('aHeight is not a number!')
              if (typeof bHeight !== 'number') throw new Error('bHeight is not a number!')
              return args.direction === 'ASC' ? aHeight - bHeight : bHeight - aHeight
            })
            return { ...existing, items }
          }
        },
        merge(existing, incoming, { readField, args }) {
          const items = { ...existing?.items }
          incoming.items.forEach((item: StoreObject | Reference) => {
            //Item is a BlockHashReference
            const height = readField('height', item) //readField('height', readField('block', item))
            if (typeof height !== 'number') throw new Error('Height is not a number!')
            items[height] = item
          })
          return { ...existing, ...incoming, items }
        }
      }
    }
  },
  IAddress: {
    keyFields: ['address'],
    merge: true,
    fields: {
      addressBlock: {
        keyArgs: ['height']
      },
      addressBlocks: {
        keyArgs: ['direction'],
        read(existing, { args, readField }) {
          if (!args?.direction) throw new Error('Direction is required')
          if (existing) {
            const items = Object.values<StoreObject | Reference>(existing.items)
            items.sort((a, b) => {
              const aHeight = readField('height', a)
              const bHeight = readField('height', b)
              if (typeof aHeight !== 'number') throw new Error('aHeight is not a number!')
              if (typeof bHeight !== 'number') throw new Error('bHeight is not a number!')
              return args.direction === 'ASC' ? aHeight - bHeight : bHeight - aHeight
            })
            return { ...existing, items }
          }
        },
        merge(existing, incoming, { readField, args, variables, cache }) {
          const items = { ...existing?.items }
          incoming.items.forEach((item: StoreObject | Reference) => {
            if (!isReference(item)) throw new Error('Item is not a reference!')
            const height = JSON.parse(item.__ref.substring(item.__ref.indexOf('{'))).height
            if (typeof height !== 'number') throw new Error('Height is not a number!')
            items[height] = item
          })
          return { ...existing, ...incoming, items }
        }
      },
      unconfirmedTransactions: {
        keyArgs: false,
        read(existing, { args, readField }) {
          if (!args?.direction) throw new Error('Direction is required')
          if (existing) {
            const items = Object.values<StoreObject | Reference>(existing.items)
            items.sort((a, b) => {
              const aTimestamp = readField('timestamp', a)
              const bTimestamp = readField('timestamp', b)
              if (typeof aTimestamp !== 'number') throw new Error('aTimestamp is not a number!')
              if (typeof bTimestamp !== 'number') throw new Error('bTimestamp is not a number!')
              return args.direction === 'ASC' ? aTimestamp - bTimestamp : bTimestamp - aTimestamp
            })

            return { ...existing, items }
          }
        },
        merge(existing, incoming, { readField, args }) {
          const items = { ...existing?.items }
          incoming.items.forEach((item: StoreObject | Reference) => {
            const txid = readField('txid', item)
            if (typeof txid !== 'string') throw new Error('txid is not a string!')
            items[txid] = item
          })
          return { ...incoming, items }
        }
      },
      ohlc: {
        keyArgs: false,
        read(existing) {
          if (existing) {
            return { ...existing, items: Object.values(existing.items) }
          }
        },
        merge(existing, incoming, { readField, args }) {
          const items = { ...existing?.items }
          incoming.items.forEach((item: StoreObject | Reference) => {
            const timestamp = readField('timestamp', item)
            if (typeof timestamp !== 'number') throw new Error('timestamp is not a number!')
            items[timestamp] = item
          })
          return { ...incoming, items }
        }
      }
    }
  },
  IBlock: {
    keyFields: ['hash'],
    merge: true,
    fields: {
      transactions: {
        keyArgs: false,
        read(existing) {
          if (existing) {
            return { ...existing, items: Object.values(existing.items) }
          }
        },
        merge(existing, incoming, { readField, args }) {
          const items = { ...existing?.items }

          incoming.items.forEach((item: StoreObject | Reference) => {
            const txN = readField('txN', item)
            if (typeof txN !== 'number') throw new Error('txN is not a number!')
            items[txN] = item
          })
          return { ...existing, ...incoming, items }
        }
      }
    }
  },
  IBlockHash: { keyFields: ['height'], merge: true },
  ITransaction: {
    keyFields: ['txid'],
    merge: true,
    fields: {
      inputs: {
        keyArgs: false,
        read(existing) {
          if (existing) {
            return { ...existing, items: Object.values(existing.items) }
          }
        },
        merge(existing, incoming, { readField, args }) {
          const items = { ...existing?.items }

          incoming.items.forEach((item: StoreObject | Reference) => {
            const spendingIndex = readField('spendingIndex', item)
            if (typeof spendingIndex !== 'number')
              throw new Error('speendingIndex is not a number!')
            items[spendingIndex] = item
          })
          return { ...incoming, items }
        }
      },
      outputs: {
        keyArgs: false,
        read(existing) {
          if (existing) {
            return { ...existing, items: Object.values(existing.items) }
          }
        },
        merge(existing, incoming, { readField, args }) {
          const items = { ...existing?.items }

          incoming.items.forEach((item: StoreObject | Reference) => {
            const n = readField('n', item)
            if (typeof n !== 'number') throw new Error('n is not a number!')
            items[n] = item
          })
          return { ...incoming, items }
        }
      }
    }
  },
  IConfirmedTransaction: { keyFields: ['height', 'txN'], merge: true },
  IUnconfirmedTransaction: { keyFields: ['txid'], merge: true },
  IAddressTransaction: { keyFields: false },
  IAddressBalanceChange: { keyFields: false },
  IAddressBlock: {
    keyFields: ['height', 'address', ['address']],
    merge: true,
    fields: {
      transactions: {
        keyArgs: false,

        read(existing, { readField, args }) {
          if (!args?.direction) throw new Error('Direction is required')
          if (existing) {
            const items = Object.values<StoreObject | Reference>(existing.items)
            items.sort((a, b) => {
              const atTxN = readField('txN', a)
              const bTxN = readField('txN', b)
              if (typeof atTxN !== 'number') throw new Error('atTxN is not a number!')
              if (typeof bTxN !== 'number') throw new Error('bTxN is not a number!')
              return args.direction === 'ASC' ? atTxN - bTxN : bTxN - atTxN
            })
            return { ...existing, items }
          }
        },
        merge(existing, incoming, { readField, args }) {
          const items = { ...existing?.items }
          incoming.items.forEach((item: StoreObject | Reference) => {
            const txN = readField('txN', item)
            if (typeof txN !== 'number') throw new Error('txN not a number')
            items[txN] = item
          })
          return { ...incoming, items }
        }
      }
    }
  },
  IAddressUtxo: { keyFields: false },
  IScriptPubKey: { keyFields: false },
  IMempool: {
    keyFields: [],
    fields: {
      transactions: {
        keyArgs: false,
        read: (existing, { args, readField }) => {
          if (!args?.direction) throw new Error('Direction is required')
          if (existing) {
            const items = Object.values<StoreObject | Reference>(existing.items)
            items.sort((a, b) => {
              const aTimestamp = readField('timestamp', a)
              const bTimestamp = readField('timestamp', b)
              const aTxid = readField('txid', a)
              const bTxid = readField('txid', b)
              if (typeof aTimestamp !== 'number') throw new Error('aTimestamp is not a number!')
              if (typeof bTimestamp !== 'number') throw new Error('bTimestamp is not a number!')
              if (typeof aTxid !== 'string') throw new Error('aTxid is not a string!')
              if (typeof bTxid !== 'string') throw new Error('bTxid is not a string!')
              if (aTimestamp === bTimestamp) return aTxid.localeCompare(bTxid)
              return args.direction === 'ASC' ? aTimestamp - bTimestamp : bTimestamp - aTimestamp
            })
            return { ...existing, items }
          }
        },
        merge(existing, incoming, { readField, args }) {
          const items: Record<string, StoreObject | Reference> = { ...existing?.items }
          //args && 'cursor' in args ? { ...existing?.items } : {} //{ ...existing?.items }
          incoming.items.forEach((item: StoreObject | Reference) => {
            const timestamp = readField('timestamp', item)
            const txid = readField('txid', item)
            if (typeof timestamp !== 'number') throw new Error('timestamp is not a number!')
            if (typeof txid !== 'string') throw new Error('txid is not a string!')
            items[timestamp + ':' + txid] = item
          })
          return { ...existing, ...incoming, items }
        }
      }
    }
  },
  IRichList: { keyFields: false },
  IDate: {
    keyFields: false,
    fields: {
      richList: {
        keyArgs: false,
        read: (existing, { args, readField }) => {
          if (existing) {
            return { ...existing, items: Object.values(existing.items) }
          }
        },
        merge: (existing, incoming, { readField, args }) => {
          const items = { ...existing?.items }
          incoming.items.forEach((item: StoreObject | Reference) => {
            const address = readField('address', readField('address', item))
            if (typeof address !== 'string') throw new Error('address is not a string!')
            items[address] = item
          })
          return { ...existing, ...incoming, items }
        }
      },
      topGainers: {
        keyArgs: false,
        read: (existing, { args, readField }) => {
          if (existing) {
            return { ...existing, items: Object.values(existing.items) }
          }
        },
        merge: (existing, incoming, { readField, args }) => {
          const items = { ...existing?.items }
          incoming.items.forEach((item: StoreObject | Reference) => {
            const address = readField('address', readField('address', item))
            if (typeof address !== 'string') throw new Error('address is not a string!')
            items[address] = item
          })
          return { ...existing, ...incoming, items }
        }
      },
      topLosers: {
        keyArgs: false,
        read: (existing, { args, readField }) => {
          if (existing) {
            return { ...existing, items: Object.values(existing.items) }
          }
        },
        merge: (existing, incoming, { readField, args }) => {
          const items = { ...existing?.items }
          incoming.items.forEach((item: StoreObject | Reference) => {
            const address = readField('address', readField('address', item))
            if (typeof address !== 'string') throw new Error('address is not a string!')
            items[address] = item
          })
          return { ...existing, ...incoming, items }
        }
      }
    }
  },
  ITransactionInput: { keyFields: false },
  ITransactionOutput: { keyFields: false },
  IUnconfirmedAddressTransaction: { keyFields: false }
}

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BACKEND_URL ?? '',
  useGETForQueries: true
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.REACT_APP_BACKEND_URL ?? ''}/subscriptions`
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === Kind.OPERATION_DEFINITION &&
      definition.operation === OperationTypeNode.SUBSCRIPTION
    )
  },
  wsLink,
  httpLink
)

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    possibleTypes: PossibleTypesData.possibleTypes,
    typePolicies
  })
})

export const preloadQuery = createQueryPreloader(client)
