import { LoadingButton } from '@mui/lab'
import { useTransition } from 'react'
import { FragmentType, getFragmentData } from '../gql'
import { ExtractQueryRefHandlers } from '../util/apolloTypeUtils'
import { UnconfirmedTransactionsTableFragment } from './UnconfirmedTransactionsTable'
import { UnconfirmedTransactionFragment } from './UnconfirmedTransactionsTableRow'
import { MempoolLoader } from './mempoolLoader'

type FetchMoreType = ExtractQueryRefHandlers<MempoolLoader>['fetchMore']

export function UnconfirmedTransactionsLoadMoreButton({
  query,
  fetchMore
}: {
  query: FragmentType<typeof UnconfirmedTransactionsTableFragment>
  fetchMore: FetchMoreType
}) {
  const [isLoading, startTransition] = useTransition()
  function handleFetchMore(args: Parameters<typeof fetchMore>[0]) {
    startTransition(() => {
      fetchMore(args)
    })
  }
  const { items, hasMore } = getFragmentData(UnconfirmedTransactionsTableFragment, query)
  if (!hasMore) return null
  const lastItem = items[items.length - 1]
  if (!lastItem) return null
  const { timestamp, txid } = getFragmentData(UnconfirmedTransactionFragment, lastItem)
  return (
    <LoadingButton
      loading={isLoading}
      onClick={(e) => {
        handleFetchMore({
          variables: {
            unconfirmedTransactionsCursor: {
              timestamp,
              txid
            }
          }
        })
      }}
    >
      Load more
    </LoadingButton>
  )
}
