import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'
import { dateToDayjs, dayjsToQueryVariable } from '../util/DateUtils'

const topGainersQuery = graphql(`
  query topGainers(
    $coin: String!
    $date: String!
    $topGainersCursor: AddressBalanceChangeCursor
    $topGainersLimit: Int
  ) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      date(date: $date) {
        topGainers(direction: DESC, cursor: $topGainersCursor, limit: $topGainersLimit) {
          ...TopGainersTableFragment
        }
      }
    }
  }
`)

export const createTopGainersLoader = (defaultCoin?: string) =>
  (({ params: { coin = defaultCoin, date } }) => {
    if (!coin) throw new Error('Param coin is required')
    return preloadQuery(topGainersQuery, {
      variables: { coin, date: dayjsToQueryVariable(dateToDayjs(date)) }
    })
  }) satisfies LoaderFunction

export type TopGainersLoader = ReturnType<typeof createTopGainersLoader>
