import { LoadingButton } from '@mui/lab'
import { useTransition } from 'react'
import { FragmentType, getFragmentData, graphql } from '../gql'
import { ExtractQueryRefHandlers } from '../util/apolloTypeUtils'
import { blocksTableFragment, blocksTableItemFragment } from './BlocksTable'
import { BlocksLoader } from './blocksLoader'

type FetchMoreType = ExtractQueryRefHandlers<BlocksLoader>['fetchMore']

const blocksLoadMoreFragment = graphql(`
  fragment BlocksLoadMoreFragment on PaginatedIBlockHashResponse {
    ...BlocksTableFragment
    hasMore
  }
`)

export function BlocksLoadMoreButton({
  query,
  fetchMore
}: {
  query: FragmentType<typeof blocksLoadMoreFragment>
  fetchMore: FetchMoreType
}) {
  const [isLoading, startTransition] = useTransition()
  function handleFetchMore(args: Parameters<typeof fetchMore>[0]) {
    startTransition(() => {
      fetchMore(args)
    })
  }
  const data = getFragmentData(blocksLoadMoreFragment, query)
  const { items } = getFragmentData(blocksTableFragment, data)
  const lastBlockHash = getFragmentData(blocksTableItemFragment, items[items.length - 1])
  if (!data.hasMore) return null
  return (
    <LoadingButton
      loading={isLoading}
      onClick={(e) => {
        handleFetchMore({ variables: { cursor: { height: lastBlockHash.height } } })
      }}
    >
      Load more
    </LoadingButton>
  )
}
