import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'

const coinsQuery = graphql(`
  query coins {
    coins {
      ...CoinItemFragment
      name
    }
  }
`)

export const coinsLoader = (() => {
  return preloadQuery(coinsQuery)
}) satisfies LoaderFunction
