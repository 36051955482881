import { useReadQuery, useSubscription } from '@apollo/client'
import { Lock, LockOpen, MoreVert, SwapHoriz } from '@mui/icons-material'
import {
  Avatar,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { FragmentType, graphql, getFragmentData } from '../gql'
import RouterLink from '../RouterLink'
import { useFormattedSize } from '../util/useFormattedSize'
import { TransactionLoader } from './transactionLoader'
import { useLoaderData } from 'react-router-typesafe'
import { useCoin } from '../coin/CoinContainer'

const TransactionDetailsFragment = graphql(`
  fragment TransactionDetails on ITransaction {
    blockHash {
      ...BlockLink
    }
    fee
    size
    ... on DashTransaction {
      instantlock
    }
  }
`)

const BlockLinkFragment = graphql(`
  fragment BlockLink on IBlockHash {
    height
    hash
    block {
      hash
      time
    }
  }
`)

const instantSendSubscription = graphql(`
  subscription instantSendSubscription($txid: String!) {
    instantSendTransactionReceived(txid: $txid) {
      txid
      instantlock
    }
  }
`)

export function TransactionCardHeader({
  txid,
  transaction
}: {
  txid: string
  transaction?: FragmentType<typeof TransactionDetailsFragment> | null
}) {
  const coin = useCoin()
  const transactionDetails = getFragmentData(TransactionDetailsFragment, transaction)
  const blockHash = getFragmentData(BlockLinkFragment, transactionDetails?.blockHash)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <CardHeader
      avatar={
        <Avatar aria-label="Transaction">
          <SwapHoriz />
        </Avatar>
      }
      title={`Transaction`}
      subheader={txid}
      subheaderTypographyProps={{ sx: { overflowWrap: 'anywhere' } }}
      action={
        <>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
            {blockHash && (
              <MenuItem
                component={RouterLink}
                to={`/${coin.toLowerCase()}/blocks/${blockHash.hash}`}
              >
                Open containing block
              </MenuItem>
            )}
            <MenuItem component={RouterLink} to={`/${coin.toLowerCase()}/graph?txid=${txid}`}>
              Open graph visualization
            </MenuItem>
          </Menu>
        </>
      }
    ></CardHeader>
  )
}

function TransactionDetailsInner(props: {
  blockLink: React.ReactNode
  fee: React.ReactNode
  size: React.ReactNode
}) {
  return (
    <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4}>
        <Typography>Status</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>{props.blockLink}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>Fee</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>{props.fee}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>Size</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>{props.size}</Typography>
      </Grid>
    </Grid>
  )
}

export function TransactionDetailsSkeleton() {
  return (
    <TransactionDetailsInner
      blockLink={<Skeleton width={70} />}
      fee={<Skeleton width={90} />}
      size={<Skeleton width={60} />}
    />
  )
}

function BlockLink(props: { blockLink: FragmentType<typeof BlockLinkFragment> }): JSX.Element {
  const queryRef = useLoaderData<TransactionLoader>()
  const { data } = useReadQuery(queryRef)
  const currentHeight = data.coinBySymbol?.blocks.items[0].height
  if (currentHeight === undefined) throw new Error('Current height is required')
  const blockHash = getFragmentData(BlockLinkFragment, props.blockLink)
  const confirmations = currentHeight - blockHash.height + 1
  return <>{confirmations} confirmations</>
}

export function InstantSendLoader() {
  const { txid } = useParams()
  if (!txid) throw new Error('Param txid is required')
  useSubscription(instantSendSubscription, { variables: { txid } })
  return (
    <>
      <LockOpen fontSize="inherit" /> Unconfirmed Transaction
    </>
  )
}

export function TransactionDetails(props: {
  transaction: FragmentType<typeof TransactionDetailsFragment>
}) {
  const coin = useCoin()
  const transactionDetails = getFragmentData(TransactionDetailsFragment, props.transaction)
  const { blockHash, fee, size } = transactionDetails
  const instantLocked =
    transactionDetails.__typename === 'DashTransaction' && transactionDetails.instantlock
  const sizeFormatted = useFormattedSize(size)
  const blockLink2 = blockHash ? (
    <BlockLink blockLink={blockHash} />
  ) : (
    <Typography
      component={'span'}
      color={(theme) => (instantLocked ? theme.palette.success.main : theme.palette.error.main)}
      fontWeight={(theme) => theme.typography.fontWeightMedium}
    >
      {transactionDetails.__typename === 'DashTransaction' ? (
        instantLocked ? (
          <>
            <Lock fontSize="inherit" /> Confirmed by InstantSend
          </>
        ) : (
          <InstantSendLoader />
        )
      ) : (
        'Unconfirmed Transaction'
      )}
    </Typography>
  )
  return (
    <TransactionDetailsInner
      blockLink={blockLink2}
      fee={`${fee} ${coin.toUpperCase()}`}
      size={sizeFormatted}
    />
  )
}
