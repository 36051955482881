import { Suspense, useEffect } from 'react'
import { Avatar, Card, CardContent, CardHeader, LinearProgress } from '@mui/material'
import { DataArray } from '@mui/icons-material'
import { CoinSymbolToName } from '../util/CoinUtil'
import { BlockPageContent } from './BlockPageContent'
import { useCoin } from '../coin/CoinContainer'

function BlocksPage() {
  const coin = useCoin()
  useEffect(() => {
    const oldTitle = document.title
    document.title = `${CoinSymbolToName[coin.toUpperCase()]} Blocks`
    return () => {
      document.title = oldTitle
    }
  }, [coin])
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar>
            <DataArray />
          </Avatar>
        }
        title="Blocks"
      />
      <CardContent>
        <Suspense
          fallback={<LinearProgress sx={{ width: '30%', maxWidth: 160, mx: 'auto', my: 5 }} />}
        >
          <BlockPageContent />
        </Suspense>
      </CardContent>
    </Card>
  )
}

export default BlocksPage
