import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { useLoaderData } from 'react-router-typesafe'
import { BlocksTable } from '../../blocks/BlocksTable'
import { CoinPageLoader } from '../CoinPage'
import { BlocksCardLayout } from './BlocksCardLayout'
import { useEffect } from 'react'
import { graphql } from '../../gql'
import { CoinSymbolToName } from '../../util/CoinUtil'

export const blockSubscription = graphql(`
  subscription blockReceived2($coin: String!) {
    blockReceived(symbol: $coin) {
      ...BlocksTableRowFragment
    }
  }
`)

export function BlocksCard() {
  const queryRef = useLoaderData<CoinPageLoader>()
  const { data } = useReadQuery(queryRef)
  const { subscribeToMore } = useQueryRefHandlers(queryRef)
  const coin = data.coinBySymbol
  const bip44_symbol = coin?.bip44_symbol
  useEffect(() => {
    if (!bip44_symbol) return
    return subscribeToMore({
      document: blockSubscription,
      variables: { coin: bip44_symbol },
      updateQuery: (prev, { subscriptionData }) => {
        const coinBySymbol = prev.coinBySymbol
        if (!coinBySymbol) throw new Error('prev is undefined')
        const block = subscriptionData.data.blockReceived
        const { hash, height } = block
        const oldBlocks = prev.coinBySymbol?.blocks.items || []
        const items = [
          {
            hash,
            height,
            block,
            __typename: `${CoinSymbolToName[coinBySymbol.bip44_symbol]}BlockHash`
          },
          ...oldBlocks
        ]
        return {
          ...prev,
          coinBySymbol: {
            ...coinBySymbol,
            blocks: {
              ...coinBySymbol.blocks,
              items
            }
          }
        } as typeof prev
      }
    })
  }, [subscribeToMore, bip44_symbol])
  if (!coin) throw new Error('Coin not found')
  return (
    <BlocksCardLayout
      content={<BlocksTable query={coin.blocks} bip44_symbol={coin.bip44_symbol} limit={5} />}
    />
  )
}
