import { LoadingButton } from '@mui/lab'
import { useTransition } from 'react'
import { FragmentType, getFragmentData } from '../gql'
import { ExtractQueryRefHandlers } from '../util/apolloTypeUtils'
import { TopGainersTableFragment } from './TopGainersTable'
import { TopGainersLoader } from './topGainersLoader'

type FetchMoreType = ExtractQueryRefHandlers<TopGainersLoader>['fetchMore']

export function TopGainersLoadMoreButton({
  query,
  fetchMore
}: {
  query: FragmentType<typeof TopGainersTableFragment>
  fetchMore: FetchMoreType
}) {
  const [isLoading, startTransition] = useTransition()
  function handleFetchMore(args: Parameters<typeof fetchMore>[0]) {
    startTransition(() => {
      fetchMore(args)
    })
  }
  const { items, hasMore } = getFragmentData(TopGainersTableFragment, query)
  if (!hasMore) return null
  return (
    <LoadingButton
      loading={isLoading}
      onClick={(e) => {
        handleFetchMore({
          variables: {
            topGainersCursor: {
              balanceChange: items[items.length - 1].balanceChange,
              address: items[items.length - 1].address.address
            }
          }
        })
      }}
    >
      Load more
    </LoadingButton>
  )
}
