import { Suspense, useCallback, useEffect, useTransition } from 'react'

import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Stack
} from '@mui/material'
import { ChevronLeft, ChevronRight, DataArray } from '@mui/icons-material'
import { BlockCardContent, BlockCardContentSkeleton } from './BlockCardContent'
import { useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import { PreloadedQueryRef, useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { BlockTransactionsByHashQuery, BlockTransactionsByHeightQuery } from '../gql/graphql'
import { BlockTransactions, BlockTransactionsSkeleton } from './BlockTransactions'
import { CoinSymbolToName } from '../util/CoinUtil'
import { BlockCardHeaderAction, blockCardHeaderActionFragment } from './BlockCardHeaderAction'
import { getFragmentData, graphql } from '../gql'
import { blockSubscription } from '../coin/blocks/BlocksCard'
import { useCoin } from '../coin/CoinContainer'
import { BlockLoader } from './blockLoader'

type HeightData = Exclude<BlockTransactionsByHeightQuery['coinBySymbol'], null | undefined>

type HashData = Exclude<BlockTransactionsByHashQuery['coinBySymbol'], null | undefined>

const isHeightData = (data: HeightData | HashData): data is HeightData => {
  return 'blockByHeight' in data
}

type BlockLoaderQueryRef = ReturnType<typeof useLoaderData<BlockLoader>>
type InferArgs<Type> = Type extends PreloadedQueryRef<unknown, infer Variables> ? Variables : never
type InferData<Type> = Type extends PreloadedQueryRef<infer Data> ? Data : never

type Data = InferData<BlockLoaderQueryRef>
type Args = InferArgs<BlockLoaderQueryRef>

const lastBlockHeightFragment = graphql(`
  fragment LastBlockHeightFragment on ICoin {
    blocks(direction: DESC, limit: 1) {
      items {
        height
      }
    }
  }
`)

function LoadedBlock() {
  const queryRef = useLoaderData<BlockLoader>()
  const [loading, startTransition] = useTransition()
  const { fetchMore, subscribeToMore, refetch } = useQueryRefHandlers<Data, Pick<Args, 'cursor'>>(
    queryRef
  )
  const { data } = useReadQuery<Data>(queryRef)
  const handleFetchMore = useCallback(
    (txN: number) => {
      startTransition(() => {
        fetchMore({ variables: { cursor: { txN } } })
      })
    },
    [startTransition, fetchMore]
  )

  const bip44_symbol = data.coinBySymbol?.bip44_symbol
  useEffect(() => {
    if (!bip44_symbol) return
    return subscribeToMore({
      document: blockSubscription,
      variables: { coin: bip44_symbol },
      updateQuery: (prev, { subscriptionData }) => {
        const coinBySymbol = prev.coinBySymbol
        if (!coinBySymbol) throw new Error('prev is undefined')
        const block = subscriptionData.data.blockReceived
        const { hash, height } = block
        const oldBlocks = coinBySymbol?.blocks.items || []
        const items = [
          {
            hash,
            height,
            block,
            __typename: `${CoinSymbolToName[coinBySymbol.bip44_symbol]}BlockHash`
          },
          ...oldBlocks
        ]
        const prev2: Data = prev
        if (prev2.coinBySymbol) {
          const block2 = isHeightData(prev2.coinBySymbol)
            ? prev2.coinBySymbol.blockByHeight?.block
            : prev2.coinBySymbol.block
          if (block2) {
            const { nextBlock } = getFragmentData(blockCardHeaderActionFragment, block2)
            if (!nextBlock) {
              refetch() // Refetch to get the next block
            }
          }
        }

        return {
          ...prev,
          coinBySymbol: {
            ...coinBySymbol,
            blocks: {
              ...coinBySymbol.blocks,
              items
            }
          }
        } as typeof prev
      }
    })
  }, [subscribeToMore, bip44_symbol, refetch])

  const coin = data.coinBySymbol
  if (!coin) throw new Error('Coin not found')
  const lastBlock = getFragmentData(lastBlockHeightFragment, coin)
  const currentHeight = lastBlock?.blocks.items[0]?.height
  const block = isHeightData(coin) ? coin.blockByHeight?.block : coin.block
  if (!block) throw new Error('BLOCK NOT FOUND')
  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader
          avatar={
            <Avatar>
              <DataArray />
            </Avatar>
          }
          title={`Block ${block.height}`}
          subheader={block.hash}
          action={<BlockCardHeaderAction block={block} />}
        />
        <CardContent>
          <BlockCardContent block={block} currentHeight={currentHeight} />
        </CardContent>
      </Card>
      <BlockTransactions block={block} handleFetchMore={handleFetchMore} loading={loading} />
    </Stack>
  )
}

function BlockSkeleton({ blockId }: { blockId: string }) {
  const isHeight = Number.isInteger(Number(blockId))
  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader
          avatar={
            <Avatar>
              <DataArray />
            </Avatar>
          }
          title={
            isHeight ? (
              `Block ${blockId}`
            ) : (
              <Box>
                Block <Skeleton width={56} sx={{ display: 'inline-block' }} />
              </Box>
            )
          }
          subheader={isHeight ? <Skeleton width={640} /> : blockId}
          action={
            <>
              <IconButton disabled>
                <ChevronLeft />
              </IconButton>
              <IconButton disabled>
                <ChevronRight />
              </IconButton>
            </>
          }
        ></CardHeader>
        <CardContent>
          <BlockCardContentSkeleton />
        </CardContent>
      </Card>
      <BlockTransactionsSkeleton />
    </Stack>
  )
}

function BlockPage() {
  const { blockId } = useParams()
  if (!blockId) throw new Error('Param blockId is required')
  const coin = useCoin()
  useEffect(() => {
    const oldTitle = document.title
    document.title = `${CoinSymbolToName[coin.toUpperCase()]} Block ${blockId}`
    return () => {
      document.title = oldTitle
    }
  }, [coin, blockId])
  return (
    <Suspense fallback={<BlockSkeleton blockId={blockId} />}>
      <LoadedBlock />
    </Suspense>
  )
}

export default BlockPage
