import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  SvgIcon,
  Typography
} from '@mui/material'
import dayjs from 'dayjs'
import { Suspense, useEffect } from 'react'
import { LoaderFunction } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'
import { MempoolCardContent } from '../mempool/MempoolCardContent'
import { coinIcons, CoinSymbolToName } from '../util/CoinUtil'
import { dayjsToQueryVariable } from '../util/DateUtils'
import { BlocksCard } from './blocks/BlocksCard'
import { BlocksCardSkeleton } from './blocks/BlocksCardSkeleton'
import { RichListCard } from './rich-list/RichListCard'
import { RichListCardSkeleton } from './rich-list/RichListCardSkeleton'
import { TopGainersCard } from './top-gainers/TopGainersCard'
import { TopGainersCardSkeleton } from './top-gainers/TopGainersCardSkeleton'
import { TopLosersCard } from './top-losers/TopLosersCard'
import { TopLosersCardSkeleton } from './top-losers/TopLosersCardSkeleton'
import { UnconfirmedTransactionsCard } from './unconfirmed-transactions/UnconfirmedTransactionsCard'
import { UnconfirmedTransactionsCardSkeleton } from './unconfirmed-transactions/UnconfirmedTransactionsCardSkeleton'
import { useCoin } from './CoinContainer'

const coinPageQuery = graphql(`
  query coinPageQuery($coin: String!, $date: String!) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      blocks(direction: DESC, limit: 5) {
        ...BlocksTableFragment
      }
      mempool {
        ...MempoolCardContentFragment
        transactions(direction: DESC, limit: 5) {
          ...UnconfirmedTransactionsTableFragment
        }
      }
      date(date: $date) {
        richList(direction: DESC, limit: 5) {
          ...RichListFragment
        }
        topGainers(direction: DESC, limit: 5) {
          ...TopGainersTableFragment
        }
        topLosers(direction: ASC, limit: 5) {
          ...TopLosersTableFragment
        }
      }
    }
  }
`)

export const mempoolSubscription = graphql(`
  subscription mempoolSubscription($coin: String!) {
    mempoolUpdated(symbol: $coin) {
      ...MempoolCardContentFragment
    }
  }
`)

export const createCoinPageLoader = (defaultCoin?: string) =>
  (({ params: { coin = defaultCoin } }) => {
    if (!coin) throw new Error('Param coin is required')
    return preloadQuery(coinPageQuery, {
      variables: { coin, date: dayjsToQueryVariable(dayjs().subtract(1, 'day')) }
    })
  }) satisfies LoaderFunction

export type CoinPageLoader = ReturnType<typeof createCoinPageLoader>

function CoinPageCardContent() {
  const queryRef = useLoaderData<CoinPageLoader>()
  const { data } = useReadQuery(queryRef)
  const { subscribeToMore } = useQueryRefHandlers(queryRef)
  const coin = data.coinBySymbol
  const bip44_symbol = coin?.bip44_symbol
  useEffect(() => {
    if (!bip44_symbol) return
    return subscribeToMore({
      document: mempoolSubscription,
      variables: { coin: bip44_symbol },
      updateQuery: (prev, { subscriptionData }) => {
        const coinBySymbol = prev.coinBySymbol
        if (!coinBySymbol) throw new Error('prev is undefined')
        return {
          ...prev,
          coinBySymbol: {
            ...coinBySymbol,
            mempool: {
              ...coinBySymbol.mempool,
              ...subscriptionData.data.mempoolUpdated
            }
          }
        } as typeof prev
      }
    })
  }, [subscribeToMore, bip44_symbol])
  if (!coin) return null
  return (
    <MempoolCardContent
      query={coin.mempool}
      bip44_symbol={coin.bip44_symbol}
      transactionsTitle="Unconfirmed transactions"
      feesTitle="Mempool fees"
    />
  )
}

function CoinPageCardSkeleton() {
  return (
    <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4}>
        <Typography>Unconfirmed transactions</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>
          <Skeleton width={75} />
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>Mempool fees</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>
          <Skeleton width={120} />
        </Typography>
      </Grid>
    </Grid>
  )
}

function CoinPage() {
  const coin = useCoin()
  const icon = coinIcons[coin.toUpperCase()]
  const name = CoinSymbolToName[coin.toUpperCase()]
  return (
    <Grid spacing={2} columns={{ xs: 2, md: 4 }} container>
      <Grid item xs={4}>
        <Card sx={{ height: 1 }}>
          <CardHeader
            avatar={
              <Avatar>
                <SvgIcon component={icon} inheritViewBox sx={{ height: 1, width: 1 }} />
              </Avatar>
            }
            title={name}
            subheader={coin.toUpperCase()}
          ></CardHeader>
          <CardContent>
            <Suspense fallback={<CoinPageCardSkeleton />}>
              <CoinPageCardContent />
            </Suspense>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Suspense fallback={<BlocksCardSkeleton />}>
          <BlocksCard />
        </Suspense>
      </Grid>
      <Grid item xs={4}>
        <Suspense fallback={<UnconfirmedTransactionsCardSkeleton />}>
          <UnconfirmedTransactionsCard />
        </Suspense>
      </Grid>
      <Grid item xs={4}>
        <Suspense fallback={<RichListCardSkeleton />}>
          <RichListCard />
        </Suspense>
      </Grid>
      <Grid item xs={2}>
        <Suspense fallback={<TopGainersCardSkeleton />}>
          <TopGainersCard />
        </Suspense>
      </Grid>
      <Grid item xs={2}>
        <Suspense fallback={<TopLosersCardSkeleton />}>
          <TopLosersCard />
        </Suspense>
      </Grid>
    </Grid>
  )
}

export default CoinPage
