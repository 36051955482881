import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { useLoaderData } from 'react-router-typesafe'
import { TopGainersLoader } from './topGainersLoader'
import { TopGainersLoadMoreButton } from './TopGainersLoadMoreButton'
import { TopGainersTable } from './TopGainersTable'

export function TopGainersPageContent() {
  const queryRef = useLoaderData<TopGainersLoader>()
  const { fetchMore } = useQueryRefHandlers(queryRef)
  const { data } = useReadQuery(queryRef)
  const coin = data.coinBySymbol
  if (!coin) return null
  return (
    <>
      <TopGainersTable query={coin.date.topGainers} bip44_symbol={coin.bip44_symbol} />
      <TopGainersLoadMoreButton query={coin.date.topGainers} fetchMore={fetchMore} />
    </>
  )
}
