import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import {
  LoadingButton,
  Timeline,
  timelineContentClasses,
  timelineOppositeContentClasses,
  timelineSeparatorClasses
} from '@mui/lab'
import { Stack } from '@mui/material'
import { Suspense, useEffect, useTransition } from 'react'
import { useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import { AddressTimelineItem, AddressTimelineSkeletonItem } from './AddressTimelineItem'
import { TimelineLoader } from './timelineLoader'
import { UnconfirmedAddresTransactionsTimeline } from './UnconfirmedTransactionsTimelineItem'
import { graphql } from '../../gql'
import { useCoin } from '../../coin/CoinContainer'

const addressBlockSubscription = graphql(`
  subscription AddressBlockSubscription($coin: String!, $address: String!) {
    addressBlockReceived(symbol: $coin, address: $address) {
      ...AddressBlockFragment2
    }
  }
`)

function AddressTimelineLazy() {
  const { address } = useParams()
  const coin = useCoin()

  const queryRef = useLoaderData<TimelineLoader>()
  const { fetchMore, subscribeToMore, refetch } = useQueryRefHandlers(queryRef)
  const { data } = useReadQuery(queryRef)

  const [isLoading, startTransition] = useTransition()
  function handleFetchMore(args: Parameters<typeof fetchMore>[0]) {
    startTransition(() => {
      fetchMore(args)
    })
  }

  if (!address) throw new Error('Param address is required')

  useEffect(() => {
    return subscribeToMore({
      document: addressBlockSubscription,
      variables: { coin, address },
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev.coinBySymbol) throw new Error('prev is undefined')
        const newBlock = subscriptionData.data.addressBlockReceived
        refetch()
        return {
          ...prev,
          coinBySymbol: {
            ...prev.coinBySymbol,
            address: {
              ...prev.coinBySymbol.address,
              addressBlocks: {
                ...prev.coinBySymbol.address.addressBlocks,
                items: [newBlock, ...prev.coinBySymbol.address.addressBlocks.items]
              }
            }
          }
        } as typeof prev
      }
    })
  }, [subscribeToMore, coin, address, refetch])
  const addressObj = data.coinBySymbol?.address
  if (!addressObj) return <div>Address not found</div>
  const { addressBlocks, unconfirmedTransactions, unconfirmedTxCount, unconfirmedBalanceChange } =
    addressObj
  const confirmedBalance = addressBlocks.items[0]?.balance ?? 0
  return (
    <>
      <Timeline
        position="left"
        sx={{
          [`& .${timelineContentClasses.root}`]: {
            flex: '1 1 0'
          },
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: '1 1 0'
          },
          [`& .${timelineSeparatorClasses.root}`]: {
            flex: '0 0 12px',
            width: 12,
            overflow: 'visible'
          },
          mb: addressBlocks.hasMore ? 0 : undefined
        }}
      >
        <UnconfirmedAddresTransactionsTimeline
          query={unconfirmedTransactions}
          unconfirmedTxCount={unconfirmedTxCount}
          unconfirmedBalanceChange={unconfirmedBalanceChange}
          confirmedBalance={confirmedBalance}
        />

        {addressBlocks.items.map((ab, index, arr) => (
          <AddressTimelineItem
            key={ab.blockHash.height}
            query={ab}
            address={address}
            disableEndConnector={index === arr.length - 1 && !addressBlocks.hasMore}
            changeDay={
              index === 0 ||
              new Date(ab.blockHash.block.time).toLocaleDateString() !==
                new Date(arr[index - 1].blockHash.block.time).toLocaleDateString()
            }
          />
        ))}
      </Timeline>
      {addressBlocks.hasMore && (
        <Stack justifyContent="center" alignItems="center">
          <LoadingButton
            loading={isLoading}
            onClick={(e) => {
              handleFetchMore({
                variables: {
                  cursor: {
                    height: addressBlocks.items[addressBlocks.items.length - 1].height
                  }
                }
              })
            }}
          >
            Load more
          </LoadingButton>
        </Stack>
      )}
    </>
  )
}

function AddressTimelineLoading() {
  return (
    <Timeline position="left">
      <AddressTimelineSkeletonItem />
    </Timeline>
  )
}

function AddressTimeline() {
  return (
    <Suspense fallback={<AddressTimelineLoading />}>
      <AddressTimelineLazy />
    </Suspense>
  )
}

export default AddressTimeline
