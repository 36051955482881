import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import App from './App'
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom'
import ErrorPage from './ErrorPage'
import CoinPage, { createCoinPageLoader } from './coin/CoinPage'
import BlocksPage from './blocks/BlocksPage'
import BlockPage from './block/BlockPage'
import CoinsPage from './coins/CoinsPage'
import TransactionPage from './transaction/TransactionPage'
import AddressPage, { AddressCrumb } from './address/AddressPage'
import { client } from './ApolloClient'
import AddressTimeline from './address/timeline/AddressTimeline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TransactionCrumb } from './transaction/TransactionCrumb'
import { BlockCrumb } from './block/BlockCrumb'
import { CoinCrumb } from './coin/CoinCrumb'
import { coinsLoader } from './coins/coinsLoader'
import { createTimelineLoader } from './address/timeline/timelineLoader'
import { createAddressCandlesticksLoader } from './address/candlesticks/addressCandlesticksLoader'
import { ErrorBoundary } from 'react-error-boundary'
import { createBlocksLoader } from './blocks/blocksLoader'
import { CoinContainer, DashContainer } from './coin/CoinContainer'
import RichListPage from './rich-list/RichListPage'
import { createRichListLoader } from './rich-list/richListLoader'
import { createTopGainersLoader } from './top-gainers/topGainersLoader'
import TopGainersPage from './top-gainers/TopGainersPage'
import TopLosersPage from './top-losers/TopLosersPage'
import { MempoolPage } from './mempool/MempoolPage'
import { createMempoolLoader } from './mempool/mempoolLoader'
import { createTopLosersLoader } from './top-losers/topLosersLoader'
import { createBlockLoader } from './block/blockLoader'
import { createTransactionLoader } from './transaction/transactionLoader'

const createCoinRoutes: (coin?: string) => RouteObject[] = (coin) => [
  {
    index: true,
    loader: createCoinPageLoader(coin),
    element: <CoinPage />
  },
  { path: 'mempool', element: <MempoolPage />, loader: createMempoolLoader(coin) },
  {
    path: 'blocks',
    loader: createBlocksLoader(coin),
    element: <BlocksPage />,
    handle: {
      crumb: 'Blocks'
    }
  },
  {
    path: 'richlist/:date?',
    handle: {
      crumb: 'Rich List'
    },
    element: <RichListPage />,
    loader: createRichListLoader(coin)
  },
  {
    path: 'top-gainers/:date?',
    element: <TopGainersPage />,
    handle: {
      crumb: 'Top Gainers'
    },
    loader: createTopGainersLoader(coin)
  },
  {
    path: 'top-losers/:date?',
    element: <TopLosersPage />,
    handle: {
      crumb: 'Top Losers'
    },
    loader: createTopLosersLoader(coin)
  },
  {
    path: 'blocks',
    handle: {
      crumb: 'Blocks'
    },
    children: [
      {
        path: ':blockId',
        element: <BlockPage />,
        loader: createBlockLoader(coin),
        handle: {
          crumb: <BlockCrumb />
        }
      }
    ]
  },
  {
    path: 'transaction',
    handle: { crumb: 'Transaction', disableBreadcrumbLink: true },
    children: [
      {
        path: ':txid',
        loader: createTransactionLoader(coin),
        element: <TransactionPage />,
        errorElement: <ErrorPage />,
        handle: {
          crumb: <TransactionCrumb />
        }
      }
    ]
  },
  {
    path: 'graph',
    //element
    lazy: async () => {
      const res = await import('./graph/Graph')
      return {
        Component: res.default
      }
    }
  },
  {
    path: 'address',
    handle: {
      crumb: 'Address',
      disableBreadcrumbLink: true
    },
    children: [
      {
        path: ':address',
        element: <AddressPage />,
        handle: {
          crumb: <AddressCrumb />
        },
        children: [
          {
            path: 'chart',
            lazy: async () => {
              const res = await import('./address/candlesticks/AddressCandlestick')
              return {
                Component: res.AddressCandleStick
              }
            },
            loader: createAddressCandlesticksLoader(coin),
            handle: { crumb: 'Chart' }
          },
          {
            loader: createTimelineLoader(coin),
            path: '',
            element: <AddressTimeline />
          },
          {
            loader: createTimelineLoader(coin),
            path: 'timeline',
            element: <AddressTimeline />,
            handle: { crumb: 'Timeline' }
          }
        ]
      }
    ]
  }
]

const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    handle: {
      crumb: 'Home'
    },
    children: [
      {
        path: '/',
        loader: coinsLoader,
        element: <CoinsPage />
      },
      {
        path: 'dash',
        handle: {
          crumb: 'Dash'
        },
        element: <DashContainer />,
        errorElement: <ErrorPage />,
        children: createCoinRoutes('dash')
      },
      {
        path: ':coin',
        handle: {
          crumb: <CoinCrumb />
        },
        element: <CoinContainer />,
        errorElement: <ErrorPage />,
        children: createCoinRoutes()
      }
    ]
  }
]

const router = createBrowserRouter(routes)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={({ error }) => <div>Uncaught error: {JSON.stringify(error)}</div>}
    >
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
