import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { useLoaderData } from 'react-router-typesafe'
import { BlocksLoader } from './blocksLoader'
import { BlocksLoadMoreButton } from './BlocksLoadMoreButton'
import { BlocksTable } from './BlocksTable'
import { useEffect } from 'react'
import { blockSubscription } from '../coin/blocks/BlocksCard'
import { CoinSymbolToName } from '../util/CoinUtil'

export function BlockPageContent() {
  const queryRef = useLoaderData<BlocksLoader>()
  const { fetchMore, subscribeToMore } = useQueryRefHandlers(queryRef)
  const { data } = useReadQuery(queryRef)
  const coin = data.coinBySymbol
  const bip44_symbol = coin?.bip44_symbol
  useEffect(() => {
    if (!bip44_symbol) return
    return subscribeToMore({
      document: blockSubscription,
      variables: { coin: bip44_symbol },
      updateQuery: (prev, { subscriptionData }) => {
        const coinBySymbol = prev.coinBySymbol
        if (!coinBySymbol) throw new Error('prev is undefined')
        const block = subscriptionData.data.blockReceived
        const { hash, height } = block
        const oldBlocks = prev.coinBySymbol?.blocks.items || []
        const items = [
          {
            hash,
            height,
            block,
            __typename: `${CoinSymbolToName[coinBySymbol.bip44_symbol]}BlockHash`
          },
          ...oldBlocks
        ]
        return {
          ...prev,
          coinBySymbol: {
            ...coinBySymbol,
            blocks: {
              ...coinBySymbol.blocks,
              items
            }
          }
        } as typeof prev
      }
    })
  }, [subscribeToMore, bip44_symbol])
  if (!coin) return null
  return (
    <>
      <BlocksTable query={coin.blocks} bip44_symbol={coin.bip44_symbol} />
      <BlocksLoadMoreButton query={coin.blocks} fetchMore={fetchMore} />
    </>
  )
}
